<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ gameName }}
      </template>
    </RMPageHeader>
    <div class="row justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 col-xxxl-4">
        <new-game-panel
          :game="game"
          :is-loading="gameInfoLoading"
          :game-info="gameInfo"
        ></new-game-panel>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 col-xxxl-4" v-if="isUserLoggedIn">
        <continue-game-panel
          :game="game"
          :is-loading="gameInfoLoading"
          :game-info="gameInfo"
        ></continue-game-panel>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 col-xxxl-4">
        <low-credit-advice v-if="isUserLoggedIn" :game="game"></low-credit-advice>
        <periodic-challenges v-else :game="game"></periodic-challenges>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/config/PageRoutes";
import {mapState} from "vuex";
import ContinueGamePanel from "@/components/games/dispatch/ContinueGamePanel";
import NewGamePanel from "@/components/games/dispatch/NewGamePanel";
import PeriodicChallenges from "@/components/games/dispatch/PeriodicChallenges";
import {config} from "@/config/config";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import LowCreditAdvice from "@/components/games/dispatch/LowCreditAdvice";
import {snakeCase} from "lodash";
import {isUserLoggedIn} from "@/_helpers";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "GameDispatch",
  data() {
    return {
      gameInfoLoading: false,
      gameInfo: {},
    };
  },
  components: {
    RMPageHeader,
    LowCreditAdvice,
    NewGamePanel,
    ContinueGamePanel,
    PeriodicChallenges
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    game() {
      return this.$route.params.game;
    },
    gameName() {
      return this.$t("games.games." + this.game + ".name");
    }
  },
  methods: {
    initGame() {
      this.gameInfo = {};
      this.gameInfoLoading = true;

      gamesService
        .get_info(this.game)
        .then((data) => {
          let snakeCaseGame = snakeCase(this.game);
          this.gameInfo = data[snakeCaseGame];
          this.gameInfoLoading = !isUserLoggedIn();
        })
        .catch((error) => {
          this.gameInfoLoading = !isUserLoggedIn();
          handleError(error, router);
        });
    },
  },
  created() {
    const validGames = Object.keys(config.games.props);

    if (!validGames.includes(this.$route.params.game)) {
      this.$router.push("/");
    }

    this.initGame();
  },
  watch: {
    game() {
      this.initGame();
    },
  },
};
</script>

<style scoped></style>
