<template>
  <RMPanel v-bind:title="this.$t('games.dispatch.notEnoughCredits')">
    <template v-slot:panelBody>
      <p>
        {{ $t("games.dispatch.noCreditNoWorries") }}
        <a href="#" @click="onDailyClick">{{ $t("games.dailyStr") }}</a> {{ $t("common.and") }}
        <a href="#" @click="onWeeklyClick">{{ $t("games.weeklyStr") }}</a>
        {{ $t("games.game") }}.
      </p>
      <p>
        {{ $t("games.dispatch.getNewCredit") }}
        <router-link to="/credit-buy">{{ $t("common.here") }}</router-link>
        <span>.</span>
      </p>
    </template>
  </RMPanel>
</template>

<script>
import {config} from "@/config/config";
import router from "@/config/PageRoutes";
import RMPanel from "@/components/common/RMPanel.vue";

export default {
  name: "LowCreditAdvice",
  components: {RMPanel},
  props: {
    game: {
      type: String,
      default: "",
    },
  },
  methods: {
    onDailyClick(ev) {
      ev.preventDefault();
      router.push(
          `/${config.urls.basePeriodicGame}/${this.game}/${config.periods.daily.id}`
      );
    },
    onWeeklyClick(ev) {
      ev.preventDefault();
      router.push(
          `/${config.urls.basePeriodicGame}/${this.game}/${config.periods.weekly.id}`
      );
    },
  },
};
</script>

<style scoped></style>
